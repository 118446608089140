import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index.js";

import Home from "./views/Home/Home.vue";
import ServiciosCorporativos from "./views/ServiciosCorporativos/ServiciosCorporativos.vue";
import ServiciosIndividuales from "./views/ServiciosIndividuales/ServiciosIndividuales.vue";
import Registro from "./views/Registro.vue";
import SobreNosotros from "./views/SobreNosotros/SobreNosotros.vue";
import Login from "./views/Login.vue";
import Checkout from "./views/Checkout/Checkout.vue";
import ThanksYou from "./views/ThanskYou/ThanksYou.vue";
import PoliticasDePrivacidad from "./views/PoliticasDePrivacidad.vue";
import TerminosYCondiciones from "./views/TerminosYCondiciones.vue";
import Contacto from "./views/Contacto/Contacto.vue";
//import Error from "@/views/Error";

/*
// Dashboard
import Dashboard from "@/views/dashboard/Dashboard";
// Auth
import SignUp from "@/views/auth/SignUp";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ChangePassword from "@/views/auth/ChangePassword";
import RecoverPassword from "@/views/auth/RecoverPassword";
import BillingInfo from "@/views/auth/BillingInfo";
// Shop
import ProductList from "@/views/shop/ProductList";
import FinishCheckout from "@/views/shop/FinishCheckout";
*/

Vue.use(Router);

// TODO: Refactor routes in a separated file

const router = new Router({
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    // Dashboard
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: false }
    },
    {
      path: "/sobre-nosotros",
      name: "Sobre Nosotros",
      component: SobreNosotros,
      meta: { requiresAuth: false }
    },
    {
      path: "/servicios/corporativos",
      name: "Servicios Corporativos",
      component: ServiciosCorporativos,
      meta: { requiresAuth: false }
    },
    {
      path: "/servicios/individuales",
      name: "Servicios Individuales",
      component: ServiciosIndividuales,
      meta: { requiresAuth: false }
    },
    {
      path: "/registro",
      name: "Registros",
      component: Registro,
      meta: { requiresAuth: false }
    },
    {
      path: "/checkout",
      name: "Chekcout",
      component: Checkout,
      meta: { requiresAuth: false }
    },
    {
      path: "/checkout/thank-you/",
      name: "ThankYou",
      component: ThanksYou,
      meta: { requiresAuth: false }
    },
    {
      path: "/politicas",
      name: "PoliticasdePrivacidad",
      component: PoliticasDePrivacidad,
      meta: { requiresAuth: false }
    },
    {
      path: "/tyc",
      name: "TerminosyCondiciones",
      component: TerminosYCondiciones,
      meta: { requiresAuth: false }
    },
    {
      path: "/contacto",
      name: "Contactanos",
      component: Contacto,
      meta: { requiresAuth: false }
    }
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  const requiresAuth = getIfRequiresAuth(to);
  if (requiresAuth) {
    const isAuth = store.getters.isUserAuthenticated;
    if (isAuth) {
      next(true);
    } else {
      store.dispatch("setRedirectRoute", to);
      next("/signup");
    }
  } else {
    next(true);
  }
});

function getIfRequiresAuth(route) {
  return !route.matched.some(record => record.meta.requiresAuth === false);
}

export default router;
