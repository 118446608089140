<template>
  <v-app id="ovaldisc">
    <v-app-bar
      app
      clipped-left
      shrink-on-scroll
      prominent
      elevation="0"
      scroll-threshold="250"
    >
      <v-btn icon color="secondary" v-if="showMenu" @click="toggleReportMenu()">
        <v-icon>fas fa-list</v-icon>
      </v-btn>
      <v-app-bar-title class="navbarTitle"></v-app-bar-title>
      <v-spacer></v-spacer>
      <div
        class="tabs-class"
        style="justify-content: flex-end; display: flex; margin: auto"
      >
        <v-tabs
          style="
            justify-content: flex-end;
            display: flex;
            margin: auto;
            margin-right: 1rem;
          "
          :center-active="true"
          align-tabs="center"
          color="black"
        >
          <v-tab
            :center-active="true"
            align-tabs="center"
            @click="navigateTo('/')"
            class="tabs-custom"
            :value="1"
          >
            <p class="tab-text">inicio</p>
          </v-tab>

          <v-tab
            :center-active="true"
            align-tabs="center"
            @click="navigateTo('/sobre-nosotros')"
            class="tabs-custom"
            :value="2"
          >
            <p class="tab-text">sobre nosotros</p>
          </v-tab>
          <v-menu :center-active="true" align-tabs="center" offset-y>
            <template v-slot:activator="{ on }">
              <v-tab
                :center-active="true"
                align-tabs="center"
                @click.stop
                v-on="on"
                class="tabs-custom"
                :value="3"
              >
                <p class="tab-text">servicios</p>
              </v-tab>
            </template>
            <v-list>
              <v-list-item @click="navigateTo('/servicios/corporativos')">
                <v-list-item-title>Corporativos</v-list-item-title>
              </v-list-item>
              <v-list-item @click="navigateTo('/servicios/individuales')">
                <v-list-item-title>Individuales</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>
      </div>
      <div
        id="line"
        style="border-left: 1px solid black; height: 80%; margin-right: 2rem"
      ></div>

      <div
        id="secondMenu"
        style="
          justify-content: center;
          align-items: center;
          display: flex;
          margin: auto;
        "
      >
        <v-btn
          style="width: 10rem !important"
          color="secondary"
          @click="navigateTo('/contacto')"
        >
          Contacto
        </v-btn>

        <v-menu left bottom v-if="isUserAuthenticated" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="background: transparent"
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 17.4375C20.3135 17.4375 24.2188 13.5322 24.2188 8.71875C24.2188 3.90527 20.3135 0 15.5 0C10.6865 0 6.78125 3.90527 6.78125 8.71875C6.78125 13.5322 10.6865 17.4375 15.5 17.4375ZM23.25 19.375H19.9139C18.5697 19.9926 17.0742 20.3438 15.5 20.3438C13.9258 20.3438 12.4363 19.9926 11.0861 19.375H7.75C3.46934 19.375 0 22.8443 0 27.125V28.0938C0 29.6982 1.30176 31 2.90625 31H28.0938C29.6982 31 31 29.6982 31 28.0938V27.125C31 22.8443 27.5307 19.375 23.25 19.375Z"
                  fill="#304A6F"
                />
              </svg>
            </v-btn>
          </template>
          <v-list class="o-opacity">
            <v-list-item
              v-for="(menu, i) in appMenuItems"
              :key="i"
              @click="menu.handler"
            >
              <v-list-item-icon v-if="menu.icon">
                <v-icon>{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menu.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu left bottom offset-y v-if="!isUserAuthenticated">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="#304A6F" v-bind="attrs" v-on="on">
              <img src="./assets/user-icon.svg" alt="" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="navigateToLogin()">
              <v-list-item-title>Inicio de sesión</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateToRegister()">
              <v-list-item-title>Registro</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-main
      class="d-flex align-content-center flex-wrap"
      style="margin-top: -8rem"
    >
      <v-container fluid class="ma-0 pa-0">
        <v-row class="ma-0">
          <!-- <v-col md="1" class="hidden-sm-and-down pa-0"></v-col> -->
          <v-col class="pa-0 text-center" cols="12">
            <router-view
              @open-dialog="openDialog"
              @set-floating-action="setFloatingAction"
            ></router-view>
          </v-col>
          <!-- <v-col md="1" class="hidden-sm-and-down pa-0"></v-col> -->
        </v-row>
      </v-container>
    </v-main>
    <v-dialog width="200" v-model="processingRequest" persistent>
      <v-card>
        <v-card-title class="text-h6"> Procesando... </v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <o-message-dialog ref="messageDialog" />
    <!-- footer container -->
    <!-- footer container -->
    <div class="bg-grey-lighten-1 flex-column footer-class-container">
      <div class="d-flex justify-end background-white" fluid>
        <div class="d-flex element-info">
          <div>
            <img class="icons-width" src="./assets/Ubicacion.png" alt="" />
          </div>
          <div>
            <div class="color-infos">Ubicacion</div>
            <div>Direccion aqui</div>
          </div>
        </div>
        <div class="d-flex element-info">
          <div>
            <img class="icons-width" src="./assets/Telefono.png" alt="" />
          </div>
          <div>
            <div class="color-infos">Telefono</div>
            <div>000 000 0000</div>
          </div>
        </div>
        <div class="d-flex element-info">
          <div>
            <img class="icons-width" src="./assets/Correo.png" alt="" />
          </div>
          <div>
            <div class="color-infos">Correo</div>
            <div>correo@ejemplo.com</div>
          </div>
        </div>
      </div>
      <div class="footer-form">
        <CardAbstract class="card-component"></CardAbstract>
      </div>
      <div class="container-background">
        <div class="container-general-info">
          <div>
            <img src="./assets/higher-horizontal-logo.png" alt="" />
            <p style="width: 15rem; color: white">
              Higher Up Coaching es una empresa líder en coaching dedicada a
              potenciar a individuos y organizaciones para alcanzar su máximo
              potencial
            </p>
            <div class="icon-network-container">
              <img class="icon-network" src="./assets/facebook.png" alt="" />
              <img class="icon-network" src="./assets/instagram.png" alt="" />
              <img class="icon-network" src="./assets/whatsapp.png" alt="" />
            </div>
          </div>
          <div class="container-ul">
            <ul type="none" class="list-ul">
              <h3>Paginas</h3>
              <li @click="navigateTo('/')">
                <img src="./assets/right-row.png" alt="" /> Inicio
              </li>
              <li @click="navigateTo('/sobre-nosotros')">
                <img src="./assets/right-row.png" alt="" /> Sobre Nosotros
              </li>
              <li @click="navigateTo('/contacto')">
                <img src="./assets/right-row.png" alt="" /> Contáctanos
              </li>
              <li @click="navigateTo('/tyc')">
                <img src="./assets/right-row.png" alt="" /> Términos y
                Condiciones
              </li>
              <li @click="navigateTo('/politicas')">
                <img src="./assets/right-row.png" alt="" /> Políticas de
                Privacidad
              </li>
            </ul>
          </div>
          <div class="container-ul">
            <ul type="none" class="list-ul">
              <h3>Servicios</h3>
              <li @click="navigateTo('/servicios/corporativos')">
                <img src="./assets/right-row.png" alt="" /> Corporativos
              </li>
              <li @click="navigateTo('/servicios/individuales')">
                <img src="./assets/right-row.png" alt="" /> Individuales
              </li>
            </ul>
          </div>
        </div>
        <div class="d-flex">
          <div class="d-flex politics">
            <p
              style="font-size: small; margin-top: 0.6rem; margin-right: 45rem"
              class="copy"
            >
              Todos los derechos reservados | Higher Up Coaching 2024
            </p>
            <p
              style="font-size: small; margin-top: 0.5rem"
              @click="navigateTo('/politicas')"
            >
              Politicas de privacidad
            </p>
            <p
              style="font-size: small; margin-top: 0.5rem; margin-left: 1rem"
              @click="navigateTo('/tyc')"
            >
              Terminos y Condiciones
            </p>
          </div>
        </div>
      </div>
    </div>
    <FloatingActionButton class="float-button-custom"></FloatingActionButton>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
      processingRequest: false,
      appMenuItems: [
        {
          label: "Perfil",
          icon: "mdi-account",
          handler: this.navigateToProfile,
        },
        {
          label: "Configuración",
          icon: "mdi-settings",
          handler: this.navigateToSettings,
        },
        { label: "Cerrar sesión", icon: "mdi-logout", handler: this.logout },
      ],
      isUserAuthenticated: true,
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    toggleReportMenu() {
      this.showMenu = !this.showMenu;
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    navigateToProfile() {
      this.navigateTo("/profile");
    },
    navigateToSettings() {
      this.navigateTo("/settings");
    },
    navigateToLogin() {
      this.navigateTo("/login");
    },
    navigateToRegister() {
      this.navigateTo("/register");
    },
    logout() {
      // Implement your logout functionality here
      console.log("Logging out...");
    },
    openDialog() {
      this.$refs.messageDialog.open();
    },
    setFloatingAction(action) {
      this.$refs.floatingActionButton.setAction(action);
    },
  },
};
</script>

<style lang="scss">
.navbarTitle {
  background-image: url("assets/logo4.svg");
  height: 100%;
  width: 200px;
}
.v-app-bar--is-scrolled {
  & .navbarTitle {
    background-image: url("assets/logo3.svg");
  }
  & #line,
  & #secondMenu,
  & .tabs-class {
    display: none !important;
  }
  & .v-toolbar__content{
    background-color: transparent;

  }

}
.v-toolbar{
  background-color: transparent !important;
}
.v-app-bar {
  
  & .v-app-bar--is-scrolled {
    background-color: transparent;
  }
}
.headerLogo {
  padding: 5px;
  height: 100%;
}
.tabs-custom {
  padding: 0 4px;
  /* Reduce el padding horizontal a 4 píxeles */
  min-width: 0;
  /* Permite que los tabs se ajusten al contenido */
}

.tab-text {
  margin: 0;
  /* Elimina el margen predeterminado */
  font-size: 12px;
  /* Ajusta el tamaño de fuente según tus necesidades */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
}

.tabs-class {
  width: 60%;
}

.politics {
  margin: auto;
  justify-content: flex-end;
  display: flex;
  margin-top: 2.8rem !important;

  p {
    cursor: pointer;
    color: white !important;
  }

  p:hover {
    text-decoration: underline;
  }
}

.copy {
  margin-left: 8rem;
  margin-top: 3rem;
  color: white;
}

.element-info {
  margin-right: 5rem;
  margin-top: 1rem;
}

.color-infos {
  color: #5e5e5e;
}

.icons-width {
  margin-right: 1rem;
  width: 2.5rem;
}

.container-ul {
  margin-top: 4rem !important;
}

.list-ul {
  color: white;

  li {
    cursor: pointer;
    margin: 9px;
  }

  li:hover {
    text-decoration: underline;
  }
}

.container-general-info {
  justify-content: flex-end;
  margin: auto;
  display: flex;
  margin-right: 10rem;

  div {
    margin: 1rem 2rem 0rem 0rem;
  }
}

.card-component {
  margin-left: 10rem;
  bottom: -1.45rem;
}

.background-white {
  background-color: white;
  height: 6rem;
}

.container-background {
  background-color: #101d30;
  margin-top: -15rem;
  height: 25rem;
  width: 100%;
}

.white-background {
  background-color: #ffffff !important;
}

.o-footer > div {
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
}

.icon-network {
  margin: 1rem 1rem 0rem 0rem;
  cursor: pointer;
}

.icon-network-container {
  justify-content: flex-start;
  margin: auto;
  display: flex;
  padding-left: 0%;
}

.o-opacity {
  opacity: 0.9;
}

.blackText {
  color: black;
}

.footer-form {
  margin-top: -119px;
}

.o-menu-item {
  white-space: normal;
}

.v-card__title {
  word-break: normal !important;
}

@media screen {
  .no-screen {
    display: none !important;
  }

  .theme--light.v-application {
    /* background-color: #37526d !important; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
  }

  .od-mt-16 {
    margin-top: 16px !important;
  }
}

@media print {
  .no-print,
  nav,
  header,
  footer {
    display: none !important;
  }

  main {
    padding-left: 0rem !important;
  }

  @page {
    size: 8.5in 11in portrait;
    margin: 10mm 10mm 5mm 10mm;
  }
}

.v-messages__message {
  font-weight: 600;
}

.v-slide-group__content,
.v-tabs-bar__content,
.v-toolbar__content {
  background-color: white;
}

p {
  color: black;
  padding-top: 1rem;
}

.transparent-background {
  background-color: transparent !important;
}

.white-background {
  background-color: white !important;
}

.tabs-custom {
  padding: 0 4px;
  min-width: 0;
}

.tab-text {
  margin: 0;
  font-size: 10px;
  white-space: nowrap;
}

.tabs-class {
  width: 60%;
}

/* Asegúrate de que los elementos dentro de v-tabs sean transparentes cuando sea necesario */
.transparent-background >>> .v-tabs-bar {
  background-color: transparent !important;
}

.transparent-background >>> .v-tab {
  background-color: transparent !important;
}
</style>
